<template>
  <div class="main">
    <div class="header">
      <Header :style="circle ? 'height: 100px;' : ''" />

      <div v-if="circle" class="circles">
        <div class="circle">
          <div class="circle-div" v-for="index in 8" :key="index">
            <div></div>
          </div>
        </div>
      </div>

      <vs-row style="background: #fff">
        <vs-col
          style="background: #f2f2f2; border-radius: 27px; margin-bottom: 72px !important"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
          vs-sm="10"
          vs-md="6"
          class="m-auto"
        >
          <ClinicStep @showCircles="showCircles" />
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import ClinicStep from "./ClinicStep";

export default {
  components: {
    Header,
    ClinicStep
  },
  data() {
    return {
      circle: false
    };
  },
  methods: {
    showCircles() {
      this.circle = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400&display=swap");

body {
  background: #fff !important;
}

.mt-25 {
  margin-top: 25px;
}

.full-page-bg-color {
  background: #eff2f7;
}

.main {
  font-family: "Inter", sans-serif;
  line-height: 120%;
  letter-spacing: -0.01em;
  position: relative;
}

.create-title {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #074230;
}

.agree-button {
  width: 100%;
  border-radius: 3px;
}

.agree-icon {
  top: 65%;
  position: absolute !important;
  transform: translateY(-50%);
}

.w-100 {
  width: 100%;
}

.text-format {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #051226;
}

.agreement-link {
  text-decoration: underline;
  cursor: pointer;
}

.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.linkClass {
  z-index: 10000;
}
.style-changer {
  padding-bottom: 0.2rem !important;
}
.btn-upload-all {
  display: none;
}
.hei {
  min-height: 100vh;
  height: 100%;
}
.c {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.space {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /*word-spacing: 30px;*/
}

.phone-img {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.phone-img .icon {
  width: 70px;
  height: 125px;
  border: 3px solid #000;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  position: relative;
}

.phone-img .sub-icon {
  width: 30px;
  height: 10px;
  border-bottom: 3px solid #000;
  border-left: 3px solid #000;
  border-right: 3px solid #000;
}

.phone-img .footer-icon {
  border: 2px solid #000;
  width: 15px;
  height: 2px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  border-radius: 10px;
}

.phone-img .footer-icon-next {
  border: 2px solid #000;
  width: 5px;
  height: 2px;
  position: absolute;
  bottom: 5px;
  left: 23px;
  border-radius: 10px;
}

.mt-9 {
  margin-top: 3rem;
}

span.link {
  text-decoration: underline;
  cursor: pointer;
}

div.change-email {
  margin-bottom: 1rem;
}

.circles {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 85px;
}

.circle {
  display: flex;
}

.circles .circle-div {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: #c4c4c4;
  margin-right: 20px;
}

.form-section {
  margin: 1.5rem 0;
}

.main-label {
  font-weight: 600;
  font-size: 14px;
  color: #074230;
}

.sub-label {
  display: block;
  font-size: 0.85rem;
  color: #074230;
  margin-top: 3px;
}
</style>
