<template>
  <div
    class="custom-select"
    @blur="open = false"
  >
    <div
      class="selected"
      :class="{open: open}"
      @click="open = !open"
    >
      {{ localSelected }}
    </div>
    <div
      class="items"
      :class="{selectHide: !open}"
    >
      <div
        class="item"
        v-for="(option, i) of options"
        :key="i"
        @click="localSelected=option.label; open=false; $emit('inputSelected', option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectOption",
  props:{
    options:{
      type: Array,
      required: true
    },
    selected:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      open: false,
      localSelected: this.selected
    };
  }
};
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 35px;
  line-height: 35px;
}

.custom-select .selected {
  background-color: #ffffff;
  border-radius: 6px;
  color: #626262;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #626262 transparent transparent transparent;
}

.custom-select .items {
  color: #626262;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 1;
  border: 1px solid rgba(60, 60, 60, 0.26);
}

.custom-select .items div {
  color: #626262;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #7367f0;
  color: #ffffff;
}

.selectHide {
  display: none;
}
</style>