<template>
  <div class="thumb vs-con-loading__container" :class="uniqueId" :id="uniqueId">
    <div class="icon" @click="openFile(file)">
      <file-text-icon
        size="1.8x"
        class="custom-class"
        style="color: #bdbdbd;"
      ></file-text-icon>
    </div>

    <div class="close" @click="removeImage(file, field)"><span>x</span></div>
    <div class="file-name" @click="openFile(file)">
      {{ fileName(file) }}
    </div>
  </div>
</template>

<script>
import { FileTextIcon } from "vue-feather-icons";

export default {
  name: "Thumb",
  props: ["file", "field"],
  components: {
    FileTextIcon
  },
  data() {
    return {
      uniqueId: `id-${new Date().getTime()}`
    };
  },
  methods: {
    fileName(str) {
      return `${str.substr(0, 15)}...`;
    },
    openFile(file) {
      const link = document.createElement("a");
      link.href = file;
      link.setAttribute("target", "_blank");
      link.click();
    },
    removeImage(file, selector) {
      this.$emit("removeImage", file, this.uniqueId, selector);
    }
  }
};
</script>

<style scoped>
.thumb {
  height: 90px;
  width: 90px;
  background: #fff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  margin-right: 5px;
}

.thumb .icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.close {
  height: 11px;
  width: 11px;
  background: #bdbdbd;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  left: 85%;
  top: 5px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.close span {
  font-size: 9px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: 48%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.thumb .file-name {
  cursor: pointer;
  position: absolute;
  bottom: 3px;
  left: 3px;
  color: #14d2b8;
  font-size: 10px;
  font-weight: 600;
}
</style>
