<template>
  <div>
    <div style="" class="dateClass mt-3">
      <!-- <div
        class="uploadedImages text-center"
        v-if="clinicPhotosField.length > 0"
      >
        <span
          class="viewUploadedImagesSpan"
          @click="viewUploadedImages()"
          v-if="
            loadingClass === 'councilPermits' ||
              loadingClass === 'poisonsCertificate'
          "
        >
          View uploaded files
        </span>
        <span
          v-else
          class="viewUploadedImagesSpan"
          @click="viewUploadedImages()"
          >View uploaded images</span
        >
      </div> -->

      <div style="margin-top:8px;" v-if="from !== 'details'">
        <div style="" class="image-label-wrapper">
          <div style="text-align:center;position:relative;">
            <label
              :id="`${loadingClass}`"
              :class="`${loadingClass}`"
              class="image-upload image-label vs-con-loading__container"
              style="display:inline-block;width:100%; border-radius: 5px;"
              @dragover.prevent
              @drop="drop($event)"
            >
              <span class="span-icon">
                <upload-cloud-icon
                  size="1.5x"
                  class="custom-class"
                ></upload-cloud-icon>
              </span>

              <p class="p-link">
                <span class="link">Upload a file</span> or drag and drop
              </p>

              <input
                v-if="
                  loadingClass === 'councilPermits' ||
                    loadingClass === 'poisonsCertificate'
                "
                type="file"
                multiple
                @change="uploadClinicMultipleImages($event)"
                :id="`id-${loadingClass}`"
                :class="`class-${loadingClass}`"
                :name="`name-${loadingClass}`"
                hidden
              />
              <input
                v-else
                type="file"
                multiple
                accept="image/*"
                @change="uploadClinicMultipleImages($event)"
                :id="`id-${loadingClass}`"
                :class="`class-${loadingClass}`"
                :name="`name-${loadingClass}`"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <span
      v-if="from && from === 'create'"
      style="font-size: 0.65rem !important;"
      class="text-danger"
    >
      {{ errors.first(loadingClass) }}
    </span>
    <span v-else class="text-danger text-sm">
      {{ errors.first(loadingClass) }}
    </span>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-popup fullscreen :title="popupTitle" :active.sync="popupActivo">
        <ViewEditClinicUploadedImages
          :images="clinicPhotosField"
          :loadingClass="loadingClass"
          :from="from"
          @removeImage="removeImage"
        />
      </vs-popup>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ViewEditClinicUploadedImages from "./ViewEditClinicOnboardingUploadedImages";
import { UploadCloudIcon } from "vue-feather-icons";

export default {
  name: "ClinicPhotos",
  components: { UploadCloudIcon, ViewEditClinicUploadedImages },
  props: {
    clinicPhotosField: {
      type: Array
    },
    from: {
      type: String,
      default: "register"
    },
    label: {
      type: String
    },
    loadingClass: {
      type: String
    },
    popupTitle: {
      type: String
    }
  },
  inject: ["$validator"],
  data() {
    return {
      popupActivo: false
    };
  },
  methods: {
    ...mapActions("clinic", ["uploadClinicImages", "removeClinicImages"]),
    drop(event) {
      event.preventDefault();
      event.stopPropagation();

      let self = this;

      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${self.loadingClass}`,
        scale: 0.45
      });

      let data = new FormData();

      for (let i = 0; i < event.dataTransfer.files.length; i++)
        data.append("clinicImages", event.dataTransfer.files[i]);

      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };

      const payload = {
        key: this.loadingClass,
        data,
        config
      };

      this.uploadClinicImages(payload)
        .then(res => {
          if (res.data.length === 1) {
            self.clinicPhotosField.push({
              photoUrl: res.data[0].Location
            });
          } else {
            res.data.forEach(datum => {
              self.clinicPhotosField.push({
                photoUrl: datum.Location
              });
            });
          }

          self.$vs.loading.close(`.${self.loadingClass} > .con-vs-loading`);

          document.getElementById(this.loadingClass).style.borderColor =
            "#cccccc";

          self.errors.remove(this.loadingClass);
        })
        .catch(err => {
          self.$vs.loading.close(`.${self.loadingClass} > .con-vs-loading`);

          document.getElementById(this.loadingClass).style.borderColor =
            "#ea5455";
          self.errors.add({ field: this.loadingClass, msg: err.data.message });

          // let errMessage = err.data.message;

          if (err && err.status === 415) {
            self.$vs.notify({
              title: "Upload Failed",
              text: err.data.message
                ? err.data.message
                : "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          } else {
            self.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }

          // self.errors.add({ field: this.loadingClass, msg: errMessage });
        });
    },
    uploadClinicMultipleImages(event) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${this.loadingClass}`,
        scale: 0.45
      });

      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };

      let data = new FormData();
      for (let i = 0; i < event.target.files.length; i++)
        data.append("clinicImages", event.target.files[i]);

      const payload = {
        key: this.loadingClass,
        data,
        config
      };

      let self = this;

      this.uploadClinicImages(payload)
        .then(res => {
          if (res.data.length === 1) {
            self.clinicPhotosField.push({
              photoUrl: res.data[0].Location
            });
          } else {
            res.data.forEach(datum => {
              self.clinicPhotosField.push({
                photoUrl: datum.Location
              });
            });
          }

          self.$vs.loading.close(`.${self.loadingClass} > .con-vs-loading`);

          document.getElementById(this.loadingClass).style.borderColor =
            "#cccccc";

          self.errors.remove(this.loadingClass);
        })
        .catch(err => {
          self.$vs.loading.close(`.${self.loadingClass} > .con-vs-loading`);

          document.getElementById(this.loadingClass).style.borderColor =
            "#ea5455";
          self.errors.add({ field: this.loadingClass, msg: err.data.message });

          // let errMessage = err.data.message;

          if (err && err.status === 415) {
            self.$vs.notify({
              title: "Upload Failed",
              text: err.data.message
                ? err.data.message
                : "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          } else {
            self.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }

          // self.errors.add({ field: this.loadingClass, msg: errMessage });
        });
    },
    viewUploadedImages() {
      this.popupActivo = true;
    },
    removeImage(imageUrl, selector) {
      this.$emit("removeImage", imageUrl, selector, this.loadingClass);
    }
  }
};
</script>

<style scoped>
.viewUploadedImagesSpan {
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  color: darkblue;
}

.image-label-wrapper {
  margin-top: 10px;
}

.image-label {
  padding: 10px;
  background: #fff;
  border: 1px dashed #bdbdbd;
}

.image-label .span-icon {
  display: block;
}

.image-upload {
  cursor: pointer;
}

p.p-link {
  font-weight: 600;
}

span.link {
  color: #0bc196;
  text-decoration: none;
}
</style>
