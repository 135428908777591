<template>
  <div class="main">
    <div v-if="showFirstStep && !currentStep">
      <div class="vx-card__title mt-30">
        <h1 class="text-center create-title">
          Help us get to know your business
        </h1>
      </div>

      <div class="full-page-bg-color w-100" style="padding: 2% 19%">
        <div class="vx-col mx-auto self-center">
          <div class="clearfix"></div>

          <div class="form-section">
            <span class="main-label">Business or Company Name</span>
            <vs-input
              :danger="errors.first('companyName') ? true : false"
              :success="!errors.first('companyName') && step1.companyName != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="business or company name"
              name="companyName"
              placeholder="Exp: Fresh Clinics Pty Ltd"
              v-model="step1.companyName"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("companyName")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Clinic Name</span>
            <vs-input
              :danger="errors.first('clinicName') ? true : false"
              :success="!errors.first('clinicName') && step1.clinicName != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="clinic name"
              name="clinicName"
              placeholder="Exp: Fresh Clinics Pty Ltd"
              v-model="step1.clinicName"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("clinicName")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">ABN</span>
            <vs-input
              :danger="errors.first('abn') ? true : false"
              :success="!errors.first('abn') && step1.abn != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="business ABN"
              name="abn"
              placeholder="Business ABN"
              v-model="step1.abn"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{ errors.first("abn") }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Website</span>
            <vs-input
              :danger="errors.first('website') ? true : false"
              :success="!errors.first('website') && step1.website != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate
              data-vv-validate-on="blur"
              data-vv-as="website"
              name="website"
              placeholder="Optional"
              v-model="step1.website"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("website")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Business Address</span>
            <div
              class="
                vs-component vs-con-input-label vs-input
                w-full
                mt-2
                vs-input-primary
                is-label-placeholder
              "
              :class="{
                'input-icon-validate-danger':
                  errors.first('displayAddress') ||
                  (step1.address.displayAddress && !coordinates[0]) ||
                  checkDisplayAddress != step1.address.displayAddress,
                'input-icon-validate-success':
                  coordinates[0] &&
                  checkDisplayAddress == step1.address.displayAddress,
              }"
            >
              <div class="vs-con-input">
                <input
                  type="text"
                  placeholder="Search Address"
                  ref="autocomplete"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  data-vv-as="business address"
                  name="displayAddress"
                  v-model="step1.address.displayAddress"
                  class="vs-inputx vs-input--input normal"
                  :class="{ hasValue: step1.address.displayAddress }"
                  style="border: 1px solid rgba(0, 0, 0, 0.2)"
                />

                <span class="input-icon-validate vs-input--icon-validate">
                  <i
                    v-if="
                      coordinates[0] &&
                      checkDisplayAddress == step1.address.displayAddress
                    "
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                  <i
                    v-else-if="
                      errors.first('displayAddress') ||
                      (step1.address.displayAddress && !coordinates[0]) ||
                      checkDisplayAddress != step1.address.displayAddress
                    "
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </div>
              <span>
                <div class="con-text-validation vs-input--text-validation">
                  <span
                    class="
                      span-text-validation span-text-validation-success
                      vs-input--text-validation-span
                    "
                  ></span>
                </div>
              </span>
            </div>
            <span
              class="text-danger text-sm"
              v-if="
                errors.first('displayAddress') ||
                (step1.address.displayAddress && !coordinates[0]) ||
                checkDisplayAddress != step1.address.displayAddress
              "
              >Enter valid address.</span
            >
          </div>

          <div class="form-section">
            <span class="main-label">Invoice Email</span>
            <vs-input
              :danger="errors.first('invoiceEmail') ? true : false"
              :success="
                !errors.first('invoiceEmail') && step1.invoiceEmail != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              data-vv-as="invoice email address"
              name="invoiceEmail"
              placeholder="Where would you like all invoices to be sent? "
              v-model="step1.invoiceEmail"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("invoiceEmail")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Best Contact Name</span>
            <vs-input
              :danger="errors.first('bestContactName') ? true : false"
              :success="
                !errors.first('bestContactName') && step1.bestContactName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="best contact name"
              name="bestContactName"
              placeholder="First Name"
              v-model="step1.bestContactName"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">
              {{ errors.first("bestContactName") }}
            </span>
          </div>

          <div class="form-section">
            <span class="main-label">Best Contact Number</span>
            <vs-input
              :danger="errors.first('bestContactNumber') ? true : false"
              :success="
                !errors.first('bestContactNumber') &&
                step1.bestContactNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="best contact number"
              name="bestContactNumber"
              placeholder="How can we reach you?"
              v-model="step1.bestContactNumber"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("bestContactNumber")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Best Contact Email</span>
            <vs-input
              :danger="errors.first('bestContactemail') ? true : false"
              :success="
                !errors.first('bestContactemail') &&
                step1.bestContactEmail != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              data-vv-as="best contact email"
              name="bestContactemail"
              type="email"
              placeholder="How can we reach you?"
              v-model="step1.bestContactEmail"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">
              {{ errors.first("bestContactemail") }}
            </span>
          </div>

          <div class="form-section">
            <span class="main-label">Instagram</span>
            <vs-input
              :danger="errors.first('instagram') ? true : false"
              :success="!errors.first('instagram') && step1.instagram != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate
              data-vv-validate-on="blur"
              data-vv-as="instagram"
              name="instagram"
              placeholder="Optional"
              v-model="step1.instagram"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("instagram")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Facebook</span>
            <vs-input
              :danger="errors.first('facebook') ? true : false"
              :success="!errors.first('facebook') && step1.facebook != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate
              data-vv-validate-on="blur"
              data-vv-as="facebook"
              name="facebook"
              placeholder="Optional"
              v-model="step1.facebook"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("facebook")
            }}</span>
          </div>

          <br />
          <vs-button class="mt-2 agree-button mb-30" @click="submit()">
            <span class="agree-text">Continue</span>
            <span class="agree-icon">
              <feather-icon
                icon="ArrowRightIcon"
                svgClasses="h-5 w-5"
                class="ml-1"
              />
            </span>
          </vs-button>
        </div>
      </div>
    </div>

    <div v-if="userData && currentStep === 'Step 1'" class="second-step">
      <div class="vx-card__title mt-45">
        <h1 class="text-center create-title">Clinic Details</h1>
      </div>
      <div class="full-page-bg-color w-100" style="padding: 2% 19%">
        <div class="vx-col mx-auto self-center">
          <div class="clearfix"></div>
          <div class="form-section">
            <div :class="{ dateClass: !errors.has('abnCertificate') }">
              <span class="main-label">ABN Certificate</span>

              <span class="sub-label"
                >Such as a drivers licence or passport. We use this to setup
                your Galderma Account.</span
              >
              <div style="" class="image-label-wrapper">
                <div
                  :class="{ 'mt-3': step2.abnCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="abnCertificate"
                    :class="{ 'six-pixel': errors.has('abnCertificate') }"
                    class="
                      image-upload
                      abnCertificate
                      image-label
                      vs-con-loading__container
                    "
                    style="
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                    "
                    @dragover.prevent
                    @drop="drop($event, 'abnCertificate')"
                  >
                    <span class="span-icon">
                      <upload-cloud-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-cloud-icon>
                    </span>

                    <p class="p-link">
                      <span class="link">Upload a file</span> or drag and drop
                    </p>
                    <input
                      type="file"
                      @change="
                        uploadImage($event, 'abnCertificate', 'abnCertificate')
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
                <div class="mb-4" v-if="step2.abnCertificate">
                  <Thumb
                    :file="step2.abnCertificate"
                    field="abnCertificate"
                    @removeImage="removeImage"
                  />
                </div>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("abnCertificate") }}
            </span>
          </div>

          <div class="form-section">
            <span class="main-label">Best Contact Number</span>
            <vs-input
              :danger="errors.first('bestContactNumber') ? true : false"
              :success="
                !errors.first('bestContactNumber') &&
                userData.bestContactNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="best contact number"
              name="bestContactNumber"
              v-model="userData.bestContactNumber"
              class="w-full mt-2"
              readonly
            />
            <span class="text-danger text-sm">{{
              errors.first("bestContactNumber")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Clinic Address 1</span>
            <vs-input
              :danger="errors.first('displayAddress') ? true : false"
              :success="
                !errors.first('displayAddress') &&
                userData.address.displayAddress != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-validate-on="blur"
              data-vv-as="clinic address 1"
              name="displayAddress"
              v-model="userData.address.displayAddress"
              class="w-full mt-2"
              readonly
            />
            <span class="text-danger text-sm">{{
              errors.first("displayAddress")
            }}</span>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label">Is this a residential address?</span>
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios1"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step2.isResidentialAddress"
                  >Yes</vs-radio
                >
              </div>

              <div>
                <vs-radio
                  vs-value="No"
                  vs-name="radios1"
                  class="vs-radio-circle"
                  v-model="step2.isResidentialAddress"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("isResidentialAddress")
              }}</span>
            </div>
          </div>

          <div class="vx-card__title mb-5 mt-5">
            <h1 class="text-center create-title" style="padding-bottom: 1rem">
              Compliance Check
            </h1>
          </div>

          <div class="form-section">
            <span class="main-label">Clinic poisons certificate</span>
            <span class="sub-label">VIC and WA only</span>
            <ClinicPhotos
              :clinicPhotosField="step2.poisonsCertificate"
              from="register"
              loadingClass="poisonsCertificate"
              label="Please upload your poisons certificate (For clinics in VIC and WA)
        (Optional)"
              popupTitle="Poisons Certificate"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.poisonsCertificate.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.poisonsCertificate"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="poisonsCertificate"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Clinic front</span>
            <span class="sub-label"
              >Upload a clear photo of the front of your clinic (street
              view).</span
            >
            <ClinicPhotos
              :clinicPhotosField="step2.clinicFrontPhoto"
              from="register"
              loadingClass="clinicFrontPhoto"
              label="Upload Clinic Front Photos (Please upload a photo of the front of your clinic (from the street))"
              popupTitle="Clinic Front Photos"
              @removeImage="removeImage"
            />

            <div v-if="step2.clinicFrontPhoto.length > 0" class="thumb-wrapper">
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicFrontPhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicFrontPhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Clinic floor</span>
            <span class="sub-label"
              >Upload a clear photo(s) of the floors of your treatment
              area(s).</span
            >
            <ClinicPhotos
              :clinicPhotosField="step2.clinicFloorPhoto"
              from="register"
              loadingClass="clinicFloorPhoto"
              label="Upload Clinic Floor Photos (Please upload photos of the floor of the treatment areas of your clinic)"
              popupTitle="Clinic Floor Photos"
              @removeImage="removeImage"
            />

            <div v-if="step2.clinicFloorPhoto.length > 0" class="thumb-wrapper">
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicFloorPhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicFloorPhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Treatment Bed</span>
            <span class="sub-label"
              >Upload a clear photo(s) of the treatment bed(s) in your
              clinic.</span
            >
            <ClinicPhotos
              :clinicPhotosField="step2.clinicTreatmentBedPhoto"
              from="register"
              loadingClass="clinicTreatmentBedPhoto"
              label="Treatment bed (Please upload photos of the treatment beds in your clinic)"
              popupTitle="Clinic Treatment Bed Photos"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.clinicTreatmentBedPhoto.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicTreatmentBedPhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicTreatmentBedPhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Drawing up area</span>
            <span class="sub-label"
              >Upload a clear photo(s) of the drawing up area in your treatment
              rooms. Please show gloves, and hand-washing facilities if
              possible.</span
            >
            <ClinicPhotos
              :clinicPhotosField="step2.clinicDrawingUpAreaPhoto"
              from="register"
              loadingClass="clinicDrawingUpAreaPhoto"
              label="Drawing up area (Please upload photos of the drawing up areas in your treatment rooms. Please show gloves and hand-washing facilities, if available)"
              popupTitle="Clinic Drawing Up Area Photos"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.clinicDrawingUpAreaPhoto.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicDrawingUpAreaPhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicDrawingUpAreaPhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Fridge</span>
            <span class="sub-label"
              >Upload a clear photo(s) of the fridge for medication storage,
              including your temperature monitoring device. Show your lock if
              possible.
            </span>
            <ClinicPhotos
              :clinicPhotosField="step2.clinicFridgePhoto"
              from="register"
              loadingClass="clinicFridgePhoto"
              label="Fridge (Please upload photos of the fridge for medication storage, including your temperature monitoring device. Show your lock if available)"
              popupTitle="Clinic Fridge Photos"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.clinicFridgePhoto.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicFridgePhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicFridgePhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Drug storage</span>
            <span class="sub-label"
              >Upload a clear photo(s) of where the medications will be stored.
              Show the ability to lock the cupboard, if possible.
            </span>
            <ClinicPhotos
              :clinicPhotosField="step2.clinicDrugStoragePhoto"
              from="register"
              loadingClass="clinicDrugStoragePhoto"
              label="Drug Storage (Please upload photos of the cupboard where other medications will be stored. Show the ability to lock the cupboard, if possible.)"
              popupTitle="Clinic Drug Storage Photos"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.clinicDrugStoragePhoto.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicDrugStoragePhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicDrugStoragePhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Bins</span>
            <span class="sub-label"
              >Upload clear photo(s) of your sharps bins and general storage
              bins.
            </span>
            <ClinicPhotos
              :clinicPhotosField="step2.clinicBinsPhoto"
              from="register"
              loadingClass="clinicBinsPhoto"
              label="Bins (Please show photos of your sharps bins and general storage bins.)"
              popupTitle="Clinic Bins Photos"
              @removeImage="removeImage"
            />

            <div v-if="step2.clinicBinsPhoto.length > 0" class="thumb-wrapper">
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicBinsPhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicBinsPhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Room Lock</span>
            <span class="sub-label"
              >Please show photos of the locks on your treatment room, if
              possible.
            </span>
            <ClinicPhotos
              :clinicPhotosField="step2.clinicRoomLockPhoto"
              from="register"
              loadingClass="clinicRoomLockPhoto"
              label="Room Lock (Please show photos of the locks on your treatment room, if available.) (Optional)"
              popupTitle="Clinic Room Lock Photos"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.clinicRoomLockPhoto.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicRoomLockPhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicRoomLockPhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label"
              >Do you have Adrenaline and Hyalese in the clinic?</span
            >
            <div class="mt-2">
              <vs-radio
                vs-value="Yes"
                vs-name="radios99"
                style="margin-right: 20px"
                v-model="step2.haveAdrenalineAndHyalaseInClinic"
                >Yes</vs-radio
              >
            </div>

            <div>
              <vs-radio
                vs-value="No"
                vs-name="radios99"
                v-model="step2.haveAdrenalineAndHyalaseInClinic"
                >No</vs-radio
              >
            </div>

            <span class="text-danger text-sm">{{
              errors.first("haveAdrenalineAndHyalaseInClinic")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Emergency Medication</span>
            <span class="sub-label"
              >Upload clear photo(s) of your epipen (or Adrenaline) and Hyalese.
              Please show expiry dates, in the images. If you do not have these
              medications you may purchase them after signup.
            </span>
            <ClinicPhotos
              :clinicPhotosField="step2.clinicEpipenAndHyalasePhoto"
              from="register"
              loadingClass="clinicEpipenAndHyalasePhoto"
              label="Emergency Medication (Please upload photos of your epipen (or adrenaline) and hyalase. Please show expiry dates in the images. If you do not have these medications you may purchase them after signing up.) (Optional)"
              popupTitle="Clinic Emergency Medication Photos"
              @removeImage="removeImage"
            />

            <div
              v-if="step2.clinicEpipenAndHyalasePhoto.length > 0"
              class="thumb-wrapper"
            >
              <swiper
                :options="swiperOption"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                :key="$vs.rtl"
                class="swiper-width"
              >
                <swiper-slide
                  class="mb-4 swiper-slide-width"
                  v-for="file in step2.clinicEpipenAndHyalasePhoto"
                  :key="file.photoUrl"
                >
                  <Thumb
                    :file="file.photoUrl"
                    field="clinicEpipenAndHyalasePhoto"
                    @removeImage="removeImage"
                  />
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label"
              >Do you intend to secure S4 medication for your clinic through
              Fresh Clinics?</span
            >
            <div class="mt-2">
              <vs-radio
                vs-value="Yes"
                vs-name="radios999"
                style="margin-right: 20px"
                v-model="step2.intendToSecureS4Medication"
                >Yes</vs-radio
              >
            </div>

            <div>
              <vs-radio
                vs-value="No"
                vs-name="radios999"
                style="margin-right: 20px"
                v-model="step2.intendToSecureS4Medication"
                >No</vs-radio
              >
            </div>

            <div>
              <vs-radio
                vs-value="Unsure"
                vs-name="radios999"
                v-model="step2.intendToSecureS4Medication"
                >Unsure</vs-radio
              >
            </div>

            <span class="text-danger text-sm">{{
              errors.first("intendToSecureS4Medication")
            }}</span>
          </div>

          <br />
          <vs-button class="mt-2 agree-button mb-30" @click="next()">
            <span class="agree-text">Continue</span>
            <span class="agree-icon">
              <feather-icon
                icon="ArrowRightIcon"
                svgClasses="h-5 w-5"
                class="ml-1"
              />
            </span>
          </vs-button>
        </div>
      </div>
    </div>

    <div v-if="userData && currentStep === 'Step 2'" class="second-step">
      <NurseOnboardingStep @changeCurrentStep="changeCurrentStep" />
    </div>

    <div v-if="userData && currentStep === 'Step 3'" class="second-step">
      <BankDetails @changeCurrentStep="changeCurrentStep" />
    </div>

    <div v-if="userData && currentStep === 'Completed'" class="second-step">
      <div class="main">
        <div class="vx-card__title mt-30">
          <h1 class="text-center create-title mb-45">Setup Completed</h1>
        </div>

        <div class="full-page-bg-color w-100" style="padding: 2% 15%">
          <div class="vx-col mx-auto self-center">
            <div class="clearfix"></div>
            <h1 class="text-center create-desc" style="margin-bottom: 35%">
              Thank you! We will review your account and provide you with an
              update within 24-48 hours with our next steps.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import ClinicOpeningHours from "@/views/components/clinics/ClinicOpeningHours.vue";
import Thumb from "@/views/components/Thumb.vue";
import ClinicPhotos from "./ClinicOnboardingPhotos";
import NurseOnboardingStep from "./NurseOnboardingStep";
import BankDetails from "./BankDetails";
import { mapActions } from "vuex";
import { UploadCloudIcon } from "vue-feather-icons";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "ClinicProfileStep",
  components: {
    ClinicOpeningHours,
    ClinicPhotos,
    UploadCloudIcon,
    NurseOnboardingStep,
    BankDetails,
    Thumb,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      currentStep: false,
      showFirstStep: true,
      checkDisplayAddress: "",
      userData: null,
      coordinates: [],
      validationKeys: {
        showIsResidentialAddressError: false,
        showIntendToSecureS4MedicationError: false,
        showHaveAdrenalineAndHyalaseInClinicError: false,
      },
      step1: {
        companyName: "",
        clinicName: "",
        abn: "",
        website: "",
        address: {
          displayAddress: "",
          state: "",
          suburb: "",
          postCode: "",
          geometry: { type: "Point", coordinates: [] },
        },
        invoiceEmail: "",
        bestContactName: "",
        bestContactNumber: "",
        bestContactEmail: "",
        instagram: "",
        facebook: "",
        profileStepCompleted: "Step 1",
      },
      step2: {
        abnCertificate: "",
        isResidentialAddress: "",
        poisonsCertificate: [],
        clinicFrontPhoto: [],
        clinicFloorPhoto: [],
        clinicTreatmentBedPhoto: [],
        clinicDrawingUpAreaPhoto: [],
        clinicFridgePhoto: [],
        clinicDrugStoragePhoto: [],
        clinicBinsPhoto: [],
        clinicRoomLockPhoto: [],
        haveAdrenalineAndHyalaseInClinic: "",
        clinicEpipenAndHyalasePhoto: [],
        intendToSecureS4Medication: "",
        profileStepCompleted: "Step 2",
      },
    };
  },
  methods: {
    ...mapActions("clinic", ["uploadClinicImages", "removeClinicImages"]),
    ...mapActions("onboard", [
      "getUserDetail",
      "submitStepOne",
      "submitStepTwo",
    ]),
    changeCurrentStep(step) {
      this.currentStep = step;
    },
    async submit() {
      const self = this;
      let isValid = await self.$validator.validate();

      if (isValid) {
        if (this.step1.address.displayAddress !== this.checkDisplayAddress)
          return;

        this.$vs.loading();
        this.submitStepOne({
          userId: this.$store.state.AppActiveUser._id,
          body: this.step1,
        })
          .then((details) => {
            this.userData = details.data.data;

            if (
              this.userData.profileStepCompleted &&
              this.userData.profileStepCompleted === "Step 1"
            ) {
              this.showFirstStep = false;
              this.$emit("showCircles");
            }

            this.currentStep = this.userData.profileStepCompleted;

            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: err.data.message,
              color: "danger",
            });
          });
      }
    },
    async next() {
      const self = this;
      let error = false;
      if (this.step2.abnCertificate == "") {
        this.errors.add({
          field: "abnCertificate",
          msg: "ABN Certificate is required",
        });
        document.getElementById("abnCertificate").style.borderColor = "#ea5455";
        error = true;
      } else {
        document.getElementById("abnCertificate").style.borderColor = "#cccccc";
        this.errors.remove("abnCertificate");
      }

      if (this.step2.isResidentialAddress == "") {
        this.errors.add({
          field: "isResidentialAddress",
          msg: "Please select one option",
        });
        error = true;
      } else {
        this.errors.remove("isResidentialAddress");
      }

      if (this.step2.poisonsCertificate.length === 0) {
        this.errors.add({
          field: "poisonsCertificate",
          msg: "The poison certificate is required.",
        });
        document.getElementById("poisonsCertificate").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("poisonsCertificate").style.borderColor =
          "#cccccc";
        this.errors.remove("poisonsCertificate");
      }

      if (this.step2.clinicFrontPhoto.length === 0) {
        this.errors.add({
          field: "clinicFrontPhoto",
          msg: "The clinic front photo is required.",
        });
        document.getElementById("clinicFrontPhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicFrontPhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicFrontPhoto");
      }

      if (this.step2.clinicFloorPhoto.length === 0) {
        this.errors.add({
          field: "clinicFloorPhoto",
          msg: "The clinic floor photo is required.",
        });
        document.getElementById("clinicFloorPhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicFloorPhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicFloorPhoto");
      }

      if (this.step2.clinicTreatmentBedPhoto.length === 0) {
        this.errors.add({
          field: "clinicTreatmentBedPhoto",
          msg: "The clinic treatment bed photo is required.",
        });
        document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicTreatmentBedPhoto");
      }

      if (this.step2.clinicDrawingUpAreaPhoto.length === 0) {
        this.errors.add({
          field: "clinicDrawingUpAreaPhoto",
          msg: "The clinic drawing up area photo is required.",
        });
        document.getElementById("clinicDrawingUpAreaPhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicDrawingUpAreaPhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicDrawingUpAreaPhoto");
      }

      if (this.step2.clinicFridgePhoto.length === 0) {
        this.errors.add({
          field: "clinicFridgePhoto",
          msg: "The clinic fridge photo is required.",
        });
        document.getElementById("clinicFridgePhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicFridgePhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicFridgePhoto");
      }

      if (this.step2.clinicDrugStoragePhoto.length === 0) {
        this.errors.add({
          field: "clinicDrugStoragePhoto",
          msg: "The clinic drug storage photo is required.",
        });
        document.getElementById("clinicDrugStoragePhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicDrugStoragePhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicDrugStoragePhoto");
      }

      if (this.step2.clinicBinsPhoto.length === 0) {
        this.errors.add({
          field: "clinicBinsPhoto",
          msg: "The clinic bins photo is required.",
        });
        document.getElementById("clinicBinsPhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicBinsPhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicBinsPhoto");
      }

      if (this.step2.clinicRoomLockPhoto.length === 0) {
        this.errors.add({
          field: "clinicRoomLockPhoto",
          msg: "The clinic room lock photo is required.",
        });
        document.getElementById("clinicRoomLockPhoto").style.borderColor =
          "#ea5455";
        error = true;
      } else {
        document.getElementById("clinicRoomLockPhoto").style.borderColor =
          "#cccccc";
        this.errors.remove("clinicRoomLockPhoto");
      }

      if (this.step2.haveAdrenalineAndHyalaseInClinic === "") {
        this.errors.add({
          field: "haveAdrenalineAndHyalaseInClinic",
          msg: "Please select one option",
        });
        error = true;
      } else {
        this.errors.remove("haveAdrenalineAndHyalaseInClinic");
      }

      if (this.step2.clinicEpipenAndHyalasePhoto.length === 0) {
        this.errors.add({
          field: "clinicEpipenAndHyalasePhoto",
          msg: "Please select one option",
        });
        document.getElementById(
          "clinicEpipenAndHyalasePhoto"
        ).style.borderColor = "#ea5455";
        error = true;
      } else {
        document.getElementById(
          "clinicEpipenAndHyalasePhoto"
        ).style.borderColor = "#cccccc";
        this.errors.remove("clinicEpipenAndHyalasePhoto");
      }

      if (this.step2.intendToSecureS4Medication === "") {
        this.errors.add({
          field: "intendToSecureS4Medication",
          msg: "Please select one option",
        });
        error = true;
      } else {
        this.errors.remove("intendToSecureS4Medication");
      }

      let isValid = await self.$validator.validate();

      if (isValid && !error) {
        this.$vs.loading();

        this.submitStepTwo({
          userId: this.$store.state.AppActiveUser._id,
          body: this.step2,
        })
          .then((details) => {
            this.userData = details.data.data;

            if (
              this.userData.profileStepCompleted &&
              this.userData.profileStepCompleted === "Step 2"
            ) {
              this.showFirstStep = false;
            }

            this.currentStep = this.userData.profileStepCompleted;

            this.$vs.loading.close();
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: err.data.message,
              color: "danger",
            });
          });
      }
    },

    removeImage(imageUrl, selector, loadingClass) {
      if (selector) {
        this.$vs.loading({
          background: "#fff",
          color: "#626262",
          container: `.${selector}`,
          scale: 0.45,
          text: "removing",
        });
        setTimeout(() => {
          this.$vs.loading.close(`.${selector} > .con-vs-loading`);
          let self = this;

          self
            .removeClinicImages(imageUrl)
            .then((response) => {
              if (loadingClass === "abnCertificate") {
                self.step2[loadingClass] = "";
              } else {
                self.step2[loadingClass] = self.step2[loadingClass].filter(
                  (value) => value.photoUrl != imageUrl
                );
              }
              // self.$vs.loading.close(`#${selector} > .con-vs-loading`);
            })
            .catch((error) => {
              self.$vs.notify({
                title: "Removing Failed",
                text: "Please try again later.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
              // self.$vs.loading.close(`#${selector} > .con-vs-loading`);
            });
        }, 1000);
      } else {
        let self = this;

        self
          .removeClinicImages(imageUrl)
          .then((response) => {
            if (loadingClass === "abnCertificate") {
              self.step2[loadingClass] = "";
            } else {
              self.step2[loadingClass] = self.step2[loadingClass].filter(
                (value) => value.photoUrl != imageUrl
              );
            }
            // self.$vs.loading.close(`#${selector} > .con-vs-loading`);
          })
          .catch((error) => {
            self.$vs.notify({
              title: "Removing Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            // self.$vs.loading.close(`#${selector} > .con-vs-loading`);
          });
      }
    },
    drop(event, loadingClass) {
      event.preventDefault();
      event.stopPropagation();

      let self = this;
      self.step2[loadingClass] = "";
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let data = new FormData();
      data.append("profileImage", event.dataTransfer.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + loadingClass, data, config)
        .then((res) => {
          document.getElementById(loadingClass).style.borderColor = "#cccccc";
          self.step2[loadingClass] = res.data.Location;
          this.errors.remove(loadingClass);

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);

          document.getElementById(loadingClass).style.borderColor = "#ea5455";
          self.errors.add({ field: loadingClass, msg: err.data.message });

          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      let self = this;
      self.step2[loadingClass] = "";
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          document.getElementById(loadingClass).style.borderColor = "#cccccc";
          self.step2[loadingClass] = res.data.Location;
          this.errors.remove(loadingClass);

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          document.getElementById(loadingClass).style.borderColor = "#ea5455";
          this.errors.add({ field: loadingClass, msg: err.data.message });
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.step1.address.displayAddress = place.formatted_address;
      this.coordinates[0] = lon;
      this.coordinates[1] = lat;

      this.step1.address.geometry.coordinates[0] = lon;
      this.step1.address.geometry.coordinates[1] = lat;

      let self = this;
      self.step1.address.suburb = "";
      self.step1.address.state = "";
      self.step1.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            self.step1.address.suburb = gAdd.long_name;
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            self.step1.address.state = gAdd.long_name;
          }
          if (gAdd.types[0] === "postal_code") {
            self.step1.address.postCode = gAdd.long_name;
          }
        });
      }
    });
  },
  created() {
    this.$vs.loading();
    if (!this.$store.state.AppActiveUser._id) {
      this.$router.push("/login");
      return;
    }

    this.getUserDetail(this.$store.state.AppActiveUser._id)
      .then((response) => {
        this.userData = response.data.data;

        if (
          this.userData.profileStepCompleted &&
          this.userData.profileStepCompleted === "Step 1"
        ) {
          this.showFirstStep = false;
          this.$emit("showCircles");
        }

        if (
          this.userData.profileStepCompleted &&
          this.userData.profileStepCompleted === "Step 2"
        ) {
          this.showFirstStep = false;
        }
        this.currentStep = this.userData.profileStepCompleted || false;
        this.$vs.loading.close();
      })
      .catch((err) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: err.data.message,
          color: "danger",
        });
      });
  },
  watch: {
    "step2.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.errors.remove("isResidentialAddress");
      }
    },
    "step2.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.errors.remove("haveAdrenalineAndHyalaseInClinic");
      }
    },
    "step2.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.errors.remove("intendToSecureS4Medication");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400&display=swap");

#app {
  background: #fff;
}

body {
  background: #fff !important;
}

.main {
  font-family: "Inter", sans-serif;
  line-height: 120%;
  letter-spacing: -0.01em;
}

.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}

.agree-button {
  width: 100%;
  border-radius: 3px;
}

.agree-icon {
  top: 65%;
  position: absolute !important;
  transform: translateY(-50%);
}

.image-label-wrapper {
  margin-top: 10px;
}

.image-label {
  padding: 10px;
  background: #fff;
  border: 1px dashed #bdbdbd;
}

.image-label .span-icon {
  display: block;
}

.image-upload {
  cursor: pointer;
}

p.p-link {
  font-weight: 600;
}

span.link {
  color: #0bc196;
  text-decoration: none;
}

.create-title {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #074230;
}

.create-desc {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #074230;
}

.second-step {
  position: relative;
}

.form-section {
  margin: 1.5rem 0;
}

.main-label {
  font-weight: 600;
  font-size: 14px;
  color: #074230;
}

.sub-label {
  display: block;
  font-size: 0.85rem;
  color: #074230;
  margin-top: 3px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-30 {
  margin-bottom: 30px;
}

.swiper-button-next,
.swiper-button-prev {
  transform: scale(0.5);
  filter: hue-rotate(40deg);
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: -30px;
}

.swiper-width {
  max-width: 400px !important;
}

.swiper-slide-width {
  width: 90px !important;
  margin-right: 5px !important;
}
</style>
