<template>
  <div class="main">
    <div class="vx-card__title mt-30">
      <h1 class="text-center create-title">
        Are you a practicing Nurse at this Clinic?
      </h1>
    </div>

    <div class="full-page-bg-color w-100" style="padding: 2% 19%">
      <div class="vx-col mx-auto self-center">
        <div class="clearfix"></div>
        <div class="form-section">
          <span class="main-label">Are you a Nurse at this clinic?</span>
          <!-- <v-select
            style="background: #fff;"
            v-model="step3.isNurse"
            name="type"
            :options="isNurseOptions"
            :clearable="false"
            :reduce="label => label.value"
            label="label"
            class="w-full mt-2"
          /> -->
          <SelectOption
            class="w-full mt-2"
            :options="isNurseOptions"
            selected="No"
            @inputSelected="inputSelected"
          />
          <span class="text-danger text-sm">{{ errors.first("type") }}</span>
        </div>

        <div v-if="step3.isNurse">
          <div class="form-section">
            <span class="main-label">First Name</span>
            <vs-input
              :danger="errors.first('firstName') ? true : false"
              :success="!errors.first('firstName')"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="firstName"
              name="firstName"
              v-model="userData.firstName"
              class="w-full mt-2"
              readonly
            />
            <span class="text-danger text-sm">{{
              errors.first("firstName")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Last Name</span>
            <vs-input
              :danger="errors.first('lastName') ? true : false"
              :success="!errors.first('lastName')"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="lastName"
              name="lastName"
              v-model="userData.lastName"
              class="w-full mt-2"
              readonly
            />
            <span class="text-danger text-sm">{{
              errors.first("lastName")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Date Of Birth - We wont tell ;)</span>
            <div class="w-full vs-con-input style-changer mt-2">
              <datepicker
                :disabled-dates="dateOfBirthDisabledDates"
                format="dd/MM/yyyy"
                v-model="step3.dateOfBirth"
                :input-class="{
                  'is-true': !errors.has('dateOfBirth') && step3.dateOfBirth,
                  'is-danger': errors.has('dateOfBirth'),
                }"
                :class="{ 'six-pixel': errors.has('dateOfBirth') }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="step3.dateOfBirth"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('dateOfBirth')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
            </div>
            <span class="text-danger text-sm">{{
              errors.first("dateOfBirth")
            }}</span>
            <input
              type="hidden"
              data-vv-as="Date Of Birth"
              data-vv-validate-on="change"
              name="dateOfBirth"
              v-validate="'required'"
              v-model="step3.dateOfBirth"
            />
          </div>

          <div class="form-section">
            <span class="main-label">Mobile phone number</span>
            <vs-input
              :danger="errors.first('contactNumber') ? true : false"
              :success="
                !errors.first('contactNumber') && step3.contactNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              placeholder="+61 412 345 678"
              data-vv-validate-on="blur"
              data-vv-as="contact number"
              name="contactNumber"
              v-model="step3.contactNumber"
              class="w-full mt-2"
            />
            <span class="text-danger text-sm">{{
              errors.first("contactNumber")
            }}</span>
          </div>

          <div class="form-section">
            <div :class="{ dateClass: !errors.has('photoId') }">
              <span class="main-label">Photo Id</span>
              <span class="sub-label"
                >Such as a drivers licence or passport
              </span>
              <div style="" class="image-label-wrapper">
                <div
                  :class="{ 'mt-3': step3.photoId }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="photoId"
                    :class="{ 'six-pixel': errors.has('photoId') }"
                    class="
                      image-upload
                      photo-id-upload
                      image-label
                      vs-con-loading__container
                    "
                    style="
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                    "
                    @dragover.prevent
                    @drop="drop($event, 'photoId', 'photo-id-upload')"
                  >
                    <span class="span-icon">
                      <upload-cloud-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-cloud-icon>
                    </span>

                    <p class="p-link">
                      <span class="link">Upload a file</span> or drag and drop
                    </p>
                    <input
                      type="file"
                      @change="
                        uploadImage($event, 'photoId', 'photo-id-upload')
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>

                <div class="mb-4" v-if="step3.photoId">
                  <Thumb
                    :file="step3.photoId"
                    field="photoId"
                    @removeImage="removeImage"
                  />
                </div>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("photoId") }}
            </span>
          </div>

          <div class="form-section">
            <span class="main-label">AHPRA Registration Number</span>
            <vs-input
              :danger="errors.first('ahpraRegistrationNumber') ? true : false"
              :success="
                !errors.first('ahpraRegistrationNumber') &&
                step3.ahpraRegistrationNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="ahpra registration number"
              name="ahpraRegistrationNumber"
              v-model="step3.ahpraRegistrationNumber"
              class="w-full mt-2"
              placeholder="MNW00000000"
            />
            <span class="text-danger text-sm">{{
              errors.first("ahpraRegistrationNumber")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">AHPRA Registration Expiry</span>
            <div class="w-full vs-con-input style-changer mt-2">
              <datepicker
                :disabled-dates="ahpraExpiryDisabledDates"
                format="dd/MM/yyyy"
                v-model="step3.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('ahpraExiryDate') && step3.ahpraExiryDate,
                  'is-danger': errors.has('ahpraExiryDate'),
                }"
                :class="{ 'six-pixel': errors.has('ahpraExiryDate') }"
                placeholder="DD/MM/YYYY"
                id="ahpraExiryDate"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="step3.ahpraExiryDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('ahpraExiryDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
            </div>
            <span class="text-danger text-sm">{{
              errors.first("ahpraExiryDate")
            }}</span>
            <input
              type="hidden"
              data-vv-as="ahpra expiry date"
              data-vv-validate-on="change"
              name="ahpraExiryDate"
              v-validate="'required'"
              v-model="step3.ahpraExiryDate"
            />
          </div>

          <div class="form-section">
            <div :class="{ dateClass: !errors.has('ahpraCertificate') }">
              <span class="main-label">AHPRA Registration Certificate</span>
              <div style="" class="image-label-wrapper">
                <div
                  :class="{ 'mt-3': step3.ahpraCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="ahpraCertificate"
                    :class="{ 'six-pixel': errors.has('ahpraCertificate') }"
                    class="
                      image-upload
                      ahpraCertificate
                      image-label
                      vs-con-loading__container
                    "
                    style="
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                    "
                    @dragover.prevent
                    @drop="drop($event, 'ahpraCertificate', 'ahpraCertificate')"
                  >
                    <span class="span-icon">
                      <upload-cloud-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-cloud-icon>
                    </span>

                    <p class="p-link">
                      <span class="link">Upload a file</span> or drag and drop
                    </p>
                    <input
                      type="file"
                      @change="
                        uploadImage(
                          $event,
                          'ahpraCertificate',
                          'ahpraCertificate'
                        )
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>

                <div class="mb-4" v-if="step3.ahpraCertificate">
                  <Thumb
                    :file="step3.ahpraCertificate"
                    field="ahpraCertificate"
                    @removeImage="removeImage"
                  />
                </div>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("ahpraCertificate") }}
            </span>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label"
                >Do you agree to tell us within 48h of any change in your
                registration status?</span
              >
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios1"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.aggreementOnAhpraRegistrationChanged"
                  >Yes</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="No"
                  vs-name="radios1"
                  class="vs-radio-circle"
                  v-model="step3.aggreementOnAhpraRegistrationChanged"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("aggreementOnAhpraRegistrationChanged")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label"
                >Have you ever had any restrictions imposed on your ability to
                practice?</span
              >
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios11"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.restrictionImposed"
                  >Yes</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="No"
                  vs-name="radios11"
                  class="vs-radio-circle"
                  v-model="step3.restrictionImposed"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("restrictionImposed")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Indemnity Provider</span>
            <vs-input
              :danger="
                errors.first('indemnityInsuranceProvider') ? true : false
              "
              :success="
                !errors.first('indemnityInsuranceProvider') &&
                step3.indemnityInsuranceProvider != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="indemnity insurance provider"
              name="indemnityInsuranceProvider"
              v-model="step3.indemnityInsuranceProvider"
              class="w-full mt-2"
              placeholder="Fresh Offers Insurance, if you need some :)"
            />
            <p>Contact us for more information</p>
            <span class="text-danger text-sm">{{
              errors.first("indemnityInsuranceProvider")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">Indemnity Insurance Expiration Date</span>
            <div class="w-full vs-con-input style-changer mt-2">
              <datepicker
                :disabled-dates="ahpraExpiryDisabledDates"
                format="dd/MM/yyyy"
                v-model="step3.indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.has('indemnityInsuranceExpiry') &&
                    step3.indemnityInsuranceExpiry,
                  'is-danger': errors.has('indemnityInsuranceExpiry'),
                }"
                :class="{ 'six-pixel': errors.has('indemnityInsuranceExpiry') }"
                placeholder="DD/MM/YYYY"
                id="indemnityInsuranceExpiry"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="step3.indemnityInsuranceExpiry"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('indemnityInsuranceExpiry')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
            </div>
            <span class="text-danger text-sm">{{
              errors.first("indemnityInsuranceExpiry")
            }}</span>
            <input
              type="hidden"
              data-vv-as="indemnity insurance expiry"
              data-vv-validate-on="change"
              name="indemnityInsuranceExpiry"
              v-validate="'required'"
              v-model="step3.indemnityInsuranceExpiry"
            />
          </div>

          <div class="form-section">
            <div :class="{ dateClass: !errors.has('insuranceCertificate') }">
              <span class="main-label">Indemnity Insurance Copy</span>
              <div style="" class="image-label-wrapper">
                <div
                  :class="{ 'mt-3': step3.insuranceCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="insuranceCertificate"
                    :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
                    class="
                      image-upload
                      insuranceCertificate
                      image-label
                      vs-con-loading__container
                    "
                    style="
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                    "
                    @dragover.prevent
                    @drop="
                      drop(
                        $event,
                        'insuranceCertificate',
                        'insuranceCertificate'
                      )
                    "
                  >
                    <span class="span-icon">
                      <upload-cloud-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-cloud-icon>
                    </span>

                    <p class="p-link">
                      <span class="link">Upload a file</span> or drag and drop
                    </p>
                    <input
                      type="file"
                      @change="
                        uploadImage(
                          $event,
                          'insuranceCertificate',
                          'insuranceCertificate'
                        )
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>

                <div class="mb-4" v-if="step3.insuranceCertificate">
                  <Thumb
                    :file="step3.insuranceCertificate"
                    field="insuranceCertificate"
                    @removeImage="removeImage"
                  />
                </div>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("insuranceCertificate") }}
            </span>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label"
                >Do you agree to tell us within 48h of any change in your
                insurance status?</span
              >
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios111"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.aggreementOnIndemnityRegistrationChanged"
                  >Yes</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="No"
                  vs-name="radios111"
                  class="vs-radio-circle"
                  v-model="step3.aggreementOnIndemnityRegistrationChanged"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("aggreementOnIndemnityRegistrationChanged")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label">Are you an RN or an EN?</span>
              <span class="sub-label"
                >If an EN, you must read the guidelines on supervision and
                ackowledge the requirements involved. Contact us if you require
                more details.
              </span>
              <div class="mt-2">
                <vs-radio
                  vs-value="RN"
                  vs-name="radios1111"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.rnOrEn"
                  >RN</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="EN"
                  vs-name="radios1111"
                  class="vs-radio-circle"
                  v-model="step3.rnOrEn"
                  >EN</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("rnOrEn")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label"
              >How many years of experience as an EN or RN?
            </span>
            <vs-input
              :danger="errors.first('expericedYearInRnOrEn') ? true : false"
              :success="
                !errors.first('expericedYearInRnOrEn') &&
                step3.expericedYearInRnOrEn != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="year of experience in RN or EN"
              name="expericedYearInRnOrEn"
              v-model="step3.expericedYearInRnOrEn"
              class="w-full mt-2"
              placeholder="exp. 1 year"
            />

            <span class="text-danger text-sm">{{
              errors.first("expericedYearInRnOrEn")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label">In what areas/feilds? </span>
            <vs-input
              :danger="errors.first('areasForRnOrEn') ? true : false"
              :success="
                !errors.first('areasForRnOrEn') && step3.areasForRnOrEn != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="area of experience"
              name="areasForRnOrEn"
              v-model="step3.areasForRnOrEn"
              class="w-full mt-2"
              placeholder="example, example,"
            />

            <span class="text-danger text-sm">{{
              errors.first("areasForRnOrEn")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >For how many years have you been a cosmetic injector?</span
            >
            <vs-input
              :danger="
                errors.first('cosmeticInjectorExperienceYears') ? true : false
              "
              :success="
                !errors.first('cosmeticInjectorExperienceYears') &&
                step3.cosmeticInjectorExperienceYears != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="cosmetic injector experience year"
              name="cosmeticInjectorExperienceYears"
              v-model="step3.cosmeticInjectorExperienceYears"
              class="w-full mt-2"
              placeholder="exp. 1 year"
            />

            <span class="text-danger text-sm">{{
              errors.first("cosmeticInjectorExperienceYears")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >Please list any other teaching that you have had in
              injectables.</span
            >
            <span class="sub-label"
              >For instance, from another nurse? A Doctor? On-the-job training?
              From a chain clinic? Please provide some details such as names,
              location duration.
            </span>
            <vs-textarea
              id="cosmeticInjectableCourses"
              v-model="step3.cosmeticInjectableCourses"
              v-validate="'required'"
              name="cosmeticInjectableCourses"
              data-vv-validate-on="blur"
              data-vv-as="nurse injectable courses"
              class="mt-2"
              placeholder="Tell us about what you have learned"
              style="background: #fff; margin-bottom: 5px"
            />
            <span class="text-danger text-sm">{{
              errors.first("cosmeticInjectableCourses")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >On average how many hours of in-clinic cosmetic practice per week
              do you currently do?
            </span>
            <vs-input
              :danger="
                errors.first('averageHourInCosmeticPracticePerWeek')
                  ? true
                  : false
              "
              :success="
                !errors.first('averageHourInCosmeticPracticePerWeek') &&
                step3.averageHourInCosmeticPracticePerWeek != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="average hour in cosmetic practice per week"
              name="averageHourInCosmeticPracticePerWeek"
              v-model="step3.averageHourInCosmeticPracticePerWeek"
              class="w-full mt-2"
              placeholder="exp. 10-50 hours"
            />

            <span class="text-danger text-sm">{{
              errors.first("averageHourInCosmeticPracticePerWeek")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >How many hours per week of in-clinic practice do you plan to do?
            </span>
            <vs-input
              :danger="
                errors.first('planForAverageHourInCosmeticPracticePerWeek')
                  ? true
                  : false
              "
              :success="
                !errors.first('planForAverageHourInCosmeticPracticePerWeek') &&
                step3.planForAverageHourInCosmeticPracticePerWeek != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="plan for desired hours in cosmetic practice per week"
              name="planForAverageHourInCosmeticPracticePerWeek"
              v-model="step3.planForAverageHourInCosmeticPracticePerWeek"
              class="w-full mt-2"
              placeholder="exp. 10-50 hours"
            />

            <span class="text-danger text-sm">{{
              errors.first("planForAverageHourInCosmeticPracticePerWeek")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >Roughly how many patients do you think you have treated?
            </span>
            <vs-input
              :danger="errors.first('numberOfPatientsTreated') ? true : false"
              :success="
                !errors.first('numberOfPatientsTreated') &&
                step3.numberOfPatientsTreated != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="number of patients treated"
              name="numberOfPatientsTreated"
              v-model="step3.numberOfPatientsTreated"
              class="w-full mt-2"
              placeholder="exp 1000"
            />

            <span class="text-danger text-sm">{{
              errors.first("numberOfPatientsTreated")
            }}</span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >What are your confident areas for toxin treatment?
            </span>

            <div class="mt-2">
              <vs-checkbox
                style="margin-left: 0"
                v-model="step3.confidentAreasForToxinTreatment['Glabella']"
                >Glabella</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['Frontalis']"
                >Frontalis</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="
                  step3.confidentAreasForToxinTreatment[
                    'Lateral Orbicularis Oculi'
                  ]
                "
                >Lateral Orbicularis Oculi</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['Lip eversion']"
                >Lip eversion</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="
                  step3.confidentAreasForToxinTreatment['Lipstick lines']
                "
                >Lipstick lines</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['Brow Lift']"
                >Brow Lift</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="
                  step3.confidentAreasForToxinTreatment['Nasalis (bunny lines)']
                "
                >Nasalis (bunny lines)</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="
                  step3.confidentAreasForToxinTreatment['LLSAN (gummy smile)']
                "
                >LLSAN (gummy smile)</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['Mentalis']"
                >Mentalis</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['DAO']"
                >DAO</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['Masseters']"
                >Masseters</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForToxinTreatment['Hyperhidrosis']"
                >Hyperhidrosis</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px; margin-bottom: 7px"
                v-model="step3.confidentAreasForToxinTreatment['Platysma']"
                >Platysma</vs-checkbox
              >

              <span class="text-danger text-sm">{{
                errors.first("confidentAreasForToxinTreatment")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label"
              >What are your confident areas for filler treatment?
            </span>

            <div class="mt-2">
              <vs-checkbox
                style="margin-left: 0"
                v-model="step3.confidentAreasForFillerTreatment['Lip filler']"
                >Lip filler</vs-checkbox
              >

              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForFillerTreatment['Chin']"
                >Chin</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForFillerTreatment['Tear Trough']"
                >Tear Trough</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForFillerTreatment['Cheeks']"
                >Cheeks</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForFillerTreatment['Jawline']"
                >Jawline</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="
                  step3.confidentAreasForFillerTreatment['Lip-stick lines']
                "
                >Lip-stick lines</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px"
                v-model="step3.confidentAreasForFillerTreatment['NLF']"
                >NLF</vs-checkbox
              >
              <vs-checkbox
                style="margin-left: 0; margin-top: 10px; margin-bottom: 7px"
                v-model="
                  step3.confidentAreasForFillerTreatment['Marionette Lines']
                "
                >Marionette Lines</vs-checkbox
              >

              <span class="text-danger text-sm">{{
                errors.first("confidentAreasForFillerTreatment")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label"
              >Are there any areas you are not confident in?
            </span>
            <vs-textarea
              id="areasNotConfidentIn"
              class="mt-2"
              v-model="step3.areasNotConfidentIn"
              v-validate="'required'"
              name="areasNotConfidentIn"
              data-vv-validate-on="blur"
              data-vv-as="areas not confident in"
              placeholder="Help us understand your needs"
              style="background: #fff; margin-bottom: 5px"
            />
            <span class="text-danger text-sm">
              {{ errors.first("areasNotConfidentIn") }}
            </span>
          </div>

          <div class="form-section">
            <span class="main-label"
              >Are there any other areas, products, or techniques that are part
              of your practice?
            </span>
            <vs-textarea
              id="additionalAreaOfPractice"
              class="mt-2"
              v-model="step3.additionalAreaOfPractice"
              v-validate="'required'"
              name="additionalAreaOfPractice"
              data-vv-validate-on="blur"
              data-vv-as="additional area of practice"
              placeholder="Help us understand your needs"
              style="background: #fff; margin-bottom: 5px"
            />
            <span class="text-danger text-sm">
              {{ errors.first("additionalAreaOfPractice") }}
            </span>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label"
                >Have you completed Hyalese training in the last 12 months?
                Optional</span
              >
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios9"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.hasCompletedHyalaseTraningInLastTwelveMonth"
                  >Yes</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="No"
                  vs-name="radios9"
                  class="vs-radio-circle"
                  v-model="step3.hasCompletedHyalaseTraningInLastTwelveMonth"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("hasCompletedHyalaseTraningInLastTwelveMonth")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <span class="main-label">Hyalese training expiration</span>
            <div class="w-full vs-con-input style-changer mt-2">
              <datepicker
                :disabled-dates="ahpraExpiryDisabledDates"
                format="dd/MM/yyyy"
                v-model="step3.hyalaseTrainingExpirationDate"
                :input-class="{
                  'is-true':
                    !errors.has('hyalaseTrainingExpirationDate') &&
                    step3.hyalaseTrainingExpirationDate,
                  'is-danger': errors.has('hyalaseTrainingExpirationDate'),
                }"
                :class="{
                  'six-pixel': errors.has('hyalaseTrainingExpirationDate'),
                }"
                placeholder="DD/MM/YYYY"
                id="hyalaseTrainingExpirationDate"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="step3.hyalaseTrainingExpirationDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('hyalaseTrainingExpirationDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
            </div>
            <span class="text-danger text-sm">{{
              errors.first("hyalaseTrainingExpirationDate")
            }}</span>
            <input
              type="hidden"
              data-vv-as="hyalase training expiration date"
              data-vv-validate-on="change"
              name="hyalaseTrainingExpirationDate"
              v-validate="'required'"
              v-model="step3.hyalaseTrainingExpirationDate"
            />
          </div>

          <div class="form-section">
            <div
              :class="{
                dateClass: !errors.has(
                  'hyalaseOrComplicationManagementCertificate'
                ),
              }"
            >
              <span class="main-label"
                >Please upload any relevant certificates for
                Hyalese/complication management</span
              >
              <span class="sub-label"
                >If you have not done a course we recommend that you do. Try the
                excellent course at ASI https://www.course.com/</span
              >
              <div style="" class="image-label-wrapper">
                <div
                  :class="{
                    'mt-3': step3.hyalaseOrComplicationManagementCertificate,
                  }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="hyalaseOrComplicationManagementCertificate"
                    :class="{
                      'six-pixel': errors.has(
                        'hyalaseOrComplicationManagementCertificate'
                      ),
                    }"
                    class="
                      image-upload
                      hyalaseOrComplicationManagementCertificate
                      image-label
                      vs-con-loading__container
                    "
                    style="
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                    "
                    @dragover.prevent
                    @drop="
                      drop(
                        $event,
                        'hyalaseOrComplicationManagementCertificate',
                        'hyalaseOrComplicationManagementCertificate'
                      )
                    "
                  >
                    <span class="span-icon">
                      <upload-cloud-icon
                        size="1.5x"
                        class="custom-class"
                      ></upload-cloud-icon>
                    </span>

                    <p class="p-link">
                      <span class="link">Upload a file</span> or drag and drop
                    </p>
                    <input
                      type="file"
                      @change="
                        uploadImage(
                          $event,
                          'hyalaseOrComplicationManagementCertificate',
                          'hyalaseOrComplicationManagementCertificate'
                        )
                      "
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>

                <div
                  class="mb-4"
                  v-if="step3.hyalaseOrComplicationManagementCertificate"
                >
                  <Thumb
                    :file="step3.hyalaseOrComplicationManagementCertificate"
                    field="hyalaseOrComplicationManagementCertificate"
                    @removeImage="removeImage"
                  />
                </div>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("hyalaseOrComplicationManagementCertificate") }}
            </span>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label"
                >Have you read and agree to adhere to the Fresh Clinics
                “Clinical Standards’ document</span
              >
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios99"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.hasReadClinicalStandardsDocuments"
                  >Yes</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="No"
                  vs-name="radios99"
                  class="vs-radio-circle"
                  v-model="step3.hasReadClinicalStandardsDocuments"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("hasReadClinicalStandardsDocuments")
              }}</span>
            </div>
          </div>

          <div class="form-section">
            <div style="">
              <span class="main-label"
                >I confirm that I have made a true and accurate delaration</span
              >
              <div class="mt-2">
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios999"
                  style="margin-right: 20px"
                  class="vs-radio-circle"
                  v-model="step3.hasConfirmedDeclarations"
                  >Yes</vs-radio
                >
              </div>

              <div style="margin-top: 5px">
                <vs-radio
                  vs-value="No"
                  vs-name="radios999"
                  class="vs-radio-circle"
                  v-model="step3.hasConfirmedDeclarations"
                  >No</vs-radio
                >
              </div>

              <span class="text-danger text-sm">{{
                errors.first("hasConfirmedDeclarations")
              }}</span>
            </div>
          </div>
        </div>

        <vs-button class="mt-2 agree-button mb-30" @click="submit()">
          <span class="agree-text">Continue</span>
          <span class="agree-icon">
            <feather-icon
              icon="ArrowRightIcon"
              svgClasses="h-5 w-5"
              class="ml-1"
            />
          </span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { UploadCloudIcon } from "vue-feather-icons";
import axios from "@/axios";
import Thumb from "@/views/components/Thumb.vue";
import SelectOption from "@/views/components/SelectOption.vue";

import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "NurseOnboardingStep",
  components: {
    vSelect,
    Datepicker,
    UploadCloudIcon,
    swiper,
    swiperSlide,
    Thumb,
    SelectOption,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 0,
        breakpoints: {
          1300: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      userData: null,
      isNurseOptions: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      dateOfBirthDisabledDates: { from: new Date() },
      ahpraExpiryDisabledDates: { to: new Date() },
      step3: {
        isNurse: false,
        dateOfBirth: new Date(moment().subtract(18, "years")),
        contactNumber: "",
        photoId: "",
        ahpraRegistrationNumber: "",
        ahpraExiryDate: "",
        ahpraCertificate: "",
        aggreementOnAhpraRegistrationChanged: "No",
        restrictionImposed: "No",
        indemnityInsuranceProvider: "",
        indemnityInsuranceExpiry: "",
        hyalaseTrainingExpirationDate: "",
        insuranceCertificate: "",
        aggreementOnIndemnityRegistrationChanged: "No",
        rnOrEn: "EN",
        expericedYearInRnOrEn: "",
        areasForRnOrEn: "",
        cosmeticInjectorExperienceYears: "",
        cosmeticInjectableCourses: "",
        averageHourInCosmeticPracticePerWeek: "",
        planForAverageHourInCosmeticPracticePerWeek: "",
        numberOfPatientsTreated: "",
        confidentAreasForToxinTreatment: {
          Glabella: false,
          Frontalis: false,
          "Lateral Orbicularis Oculi": false,
          "Lip eversion": false,
          "Lipstick lines": false,
          "Brow Lift": false,
          "Nasalis (bunny lines)": false,
          "LLSAN (gummy smile)": false,
          Mentalis: false,
          DAO: false,
          Masseters: false,
          Hyperhidrosis: false,
          Platysma: false,
        },
        confidentAreasForFillerTreatment: {
          "Lip filler": false,
          Chin: false,
          "Tear Trough": false,
          Cheeks: false,
          Jawline: false,
          "Lip-stick lines": false,
          NLF: false,
          "Marionette Lines": false,
        },
        areasNotConfidentIn: "",
        additionalAreaOfPractice: "",
        hasCompletedHyalaseTraningInLastTwelveMonth: "No",
        hyalaseOrComplicationManagementCertificate: "",
        hasReadClinicalStandardsDocuments: "No",
        hasConfirmedDeclarations: "No",
        profileStepCompleted: "Step 3",
      },
    };
  },
  methods: {
    ...mapActions("onboard", ["getUserDetail", "submitStepThree"]),
    ...mapActions("clinic", ["removeClinicImages"]),
    inputSelected(selected) {
      this.step3.isNurse = selected.value;
    },
    removeImage(imageUrl, selector, loadingClass) {
      if (selector) {
        this.$vs.loading({
          background: "#fff",
          color: "#626262",
          container: `.${selector}`,
          scale: 0.45,
          text: "removing",
        });
        setTimeout(() => {
          this.$vs.loading.close(`.${selector} > .con-vs-loading`);
          let self = this;

          self
            .removeClinicImages(imageUrl)
            .then((response) => {
              self.step3[loadingClass] = "";
            })
            .catch((error) => {
              self.$vs.notify({
                title: "Removing Failed",
                text: "Please try again later.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            });
        }, 1000);
      } else {
        let self = this;

        self
          .removeClinicImages(imageUrl)
          .then((response) => {
            self.step3[loadingClass] = "";
          })
          .catch((error) => {
            self.$vs.notify({
              title: "Removing Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },
    drop(event, key = "profileImage", loadingClass = "") {
      event.preventDefault();
      event.stopPropagation();

      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.dataTransfer.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "ahpraCertificate") {
            document.getElementById("ahpraCertificate").style.borderColor =
              "#cccccc";
            self.step3.ahpraCertificate = res.data.Location;
            this.errors.remove("ahpraCertificate");
          }

          if (key === "photoId") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            self.step3.photoId = res.data.Location;
          }

          if (key === "insuranceCertificate") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceCertificate").style.borderColor =
              "#cccccc";
            self.step3.insuranceCertificate = res.data.Location;
          }

          if (key === "trainingCertificate") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            self.step3.trainingCertificate = res.data.Location;
          }

          if (key === "hyalaseOrComplicationManagementCertificate") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseOrComplicationManagementCertificate"
            ).style.borderColor = "#cccccc";
            self.step3.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "ahpraCertificate") {
            document.getElementById("ahpraCertificate").style.borderColor =
              "#cccccc";
            self.step3.ahpraCertificate = res.data.Location;
            this.errors.remove("ahpraCertificate");
          }

          if (key === "photoId") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            self.step3.photoId = res.data.Location;
          }

          if (key === "insuranceCertificate") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceCertificate").style.borderColor =
              "#cccccc";
            self.step3.insuranceCertificate = res.data.Location;
          }

          if (key === "trainingCertificate") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            self.step3.trainingCertificate = res.data.Location;
          }

          if (key === "hyalaseOrComplicationManagementCertificate") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseOrComplicationManagementCertificate"
            ).style.borderColor = "#cccccc";
            self.step3.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    async submit() {
      const self = this;

      if (!self.step3.isNurse) {
        this.$vs.loading();
        this.submitStepThree({
          userId: this.$store.state.AppActiveUser._id,
          body: { isNurse: false, profileStepCompleted: "Step 3" },
        })
          .then((result) => {
            this.userData = result.data.data;
            this.$vs.loading.close();
            this.$emit("changeCurrentStep", "Step 3");
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: err.data.message,
              color: "danger",
            });
          });
      } else {
        let isValid = await self.$validator.validate();

        let error = false;
        if (this.step3.photoId == "") {
          this.errors.add({
            field: "photoId",
            msg: "Photo id is required",
          });
          document.getElementById("photoId").style.borderColor = "#ea5455";
          error = true;
        } else {
          document.getElementById("photoId").style.borderColor = "#cccccc";
          this.errors.remove("photoId");
        }

        if (this.step3.hyalaseOrComplicationManagementCertificate == "") {
          this.errors.add({
            field: "hyalaseOrComplicationManagementCertificate",
            msg: "Hyalase or complication management certificate is required",
          });
          document.getElementById(
            "hyalaseOrComplicationManagementCertificate"
          ).style.borderColor = "#ea5455";
          error = true;
        } else {
          document.getElementById(
            "hyalaseOrComplicationManagementCertificate"
          ).style.borderColor = "#cccccc";
          this.errors.remove("hyalaseOrComplicationManagementCertificate");
        }

        if (this.step3.ahpraCertificate == "") {
          this.errors.add({
            field: "ahpraCertificate",
            msg: "Ahpra certificate is required",
          });
          document.getElementById("ahpraCertificate").style.borderColor =
            "#ea5455";
          error = true;
        } else {
          document.getElementById("ahpraCertificate").style.borderColor =
            "#cccccc";
          this.errors.remove("ahpraCertificate");
        }

        if (this.step3.insuranceCertificate == "") {
          this.errors.add({
            field: "insuranceCertificate",
            msg: "Insurance certificate is required",
          });
          document.getElementById("insuranceCertificate").style.borderColor =
            "#ea5455";
          error = true;
        } else {
          document.getElementById("insuranceCertificate").style.borderColor =
            "#cccccc";
          this.errors.remove("insuranceCertificate");
        }

        if (this.step3.cosmeticInjectableCourses == "") {
          document.getElementById("cosmeticInjectableCourses").style.border =
            "1px solid #ea5455";
          error = true;
        } else {
          document.getElementById("cosmeticInjectableCourses").style.border =
            "1px solid #cccccc";
        }

        if (this.step3.areasNotConfidentIn == "") {
          document.getElementById("areasNotConfidentIn").style.border =
            "1px solid #ea5455";
          error = true;
        } else {
          document.getElementById("areasNotConfidentIn").style.border =
            "1px solid #cccccc";
        }

        if (this.step3.additionalAreaOfPractice == "") {
          document.getElementById("additionalAreaOfPractice").style.border =
            "1px solid #ea5455";
          error = true;
        } else {
          document.getElementById("additionalAreaOfPractice").style.border =
            "1px solid #cccccc";
        }

        if (this.step3.ahpraExiryDate == "") {
          document.getElementById("ahpraExiryDate").style.border =
            "1px solid #ea5455";
          error = true;
        } else {
          document.getElementById("ahpraExiryDate").style.border =
            "1px solid #cccccc";
        }

        if (this.step3.indemnityInsuranceExpiry == "") {
          document.getElementById("indemnityInsuranceExpiry").style.border =
            "1px solid #ea5455";
          error = true;
        } else {
          document.getElementById("indemnityInsuranceExpiry").style.border =
            "1px solid #cccccc";
        }

        if (this.step3.hyalaseTrainingExpirationDate == "") {
          document.getElementById(
            "hyalaseTrainingExpirationDate"
          ).style.border = "1px solid #ea5455";
          error = true;
        } else {
          document.getElementById(
            "hyalaseTrainingExpirationDate"
          ).style.border = "1px solid #cccccc";
        }

        let hasTrueKeys = Object.keys(
          this.step3.confidentAreasForToxinTreatment
        ).some((k) => this.step3.confidentAreasForToxinTreatment[k]);
        if (!hasTrueKeys) {
          error = true;
          this.errors.add({
            field: "confidentAreasForToxinTreatment",
            msg: "Please select at least one option",
          });
        } else {
          this.errors.remove("confidentAreasForToxinTreatment");
        }

        let hasTrueKeys1 = Object.keys(
          this.step3.confidentAreasForFillerTreatment
        ).some((k) => this.step3.confidentAreasForFillerTreatment[k]);
        if (!hasTrueKeys1) {
          error = true;
          this.errors.add({
            field: "confidentAreasForFillerTreatment",
            msg: "Please select at least one option",
          });
        } else {
          this.errors.remove("confidentAreasForFillerTreatment");
        }

        if (isValid && !error) {
          this.$vs.loading();
          this.submitStepThree({
            userId: this.$store.state.AppActiveUser._id,
            body: this.step3,
          })
            .then((result) => {
              this.userData = result.data.data;
              this.$vs.loading.close();
              this.$emit("changeCurrentStep", "Step 3");
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: err.data.message,
                color: "danger",
              });
            });
        }
      }
    },
  },
  created() {
    this.$vs.loading();
    this.getUserDetail(this.$store.state.AppActiveUser._id)
      .then((response) => {
        this.userData = response.data.data;
        this.$vs.loading.close();
      })
      .catch((err) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: err.data.message,
          color: "danger",
        });
      });
  },
  mounted() {
    this.dateOfBirthDisabledDates.from = new Date(
      moment().subtract(18, "years")
    );

    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.ahpraExpiryDisabledDates.to = d;
  },
  watch: {
    "step3.ahpraExiryDate": function (newVal, oldVal) {
      if (newVal !== "")
        document.getElementById("ahpraExiryDate").style.border =
          "1px solid #cccccc";
    },
    "step3.indemnityInsuranceExpiry": function (newVal, oldVal) {
      if (newVal !== "")
        document.getElementById("indemnityInsuranceExpiry").style.border =
          "1px solid #cccccc";
    },
    "step3.cosmeticInjectableCourses": function (newVal, oldVal) {
      if (newVal !== "")
        document.getElementById("cosmeticInjectableCourses").style.border =
          "1px solid #cccccc";
    },
    "step3.areasNotConfidentIn": function (newVal, oldVal) {
      if (newVal !== "")
        document.getElementById("areasNotConfidentIn").style.border =
          "1px solid #cccccc";
    },
    "step3.additionalAreaOfPractice": function (newVal, oldVal) {
      if (newVal !== "")
        document.getElementById("additionalAreaOfPractice").style.border =
          "1px solid #cccccc";
    },
    "step3.hyalaseTrainingExpirationDate": function (newVal, oldVal) {
      if (newVal !== "")
        document.getElementById("hyalaseTrainingExpirationDate").style.border =
          "1px solid #cccccc";
    },
    "step3.confidentAreasForToxinTreatment": {
      handler: function (newVal, oldVal) {
        const checkTrueKeys = Object.keys(newVal).some((k) => newVal[k]);
        if (checkTrueKeys)
          this.errors.remove("confidentAreasForToxinTreatment");
      },
      deep: true,
    },
    "step3.confidentAreasForFillerTreatment": {
      handler: function (newVal, oldVal) {
        const checkTrueKeys = Object.keys(newVal).some((k) => newVal[k]);
        if (checkTrueKeys)
          this.errors.remove("confidentAreasForFillerTreatment");
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400&display=swap");

.main {
  font-family: "Inter", sans-serif;
  line-height: 120%;
  letter-spacing: -0.01em;
}

.create-title {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #074230;
}

.form-section {
  margin: 1.5rem 0;
}

.main-label {
  font-weight: 600;
  font-size: 14px;
  color: #074230;
}

.sub-label {
  display: block;
  font-size: 0.85rem;
  color: #074230;
  margin-top: 3px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-30 {
  margin-bottom: 30px;
}

.agree-button {
  width: 100%;
  border-radius: 3px;
}

.agree-icon {
  top: 65%;
  position: absolute !important;
  transform: translateY(-50%);
}

.image-label-wrapper {
  margin-top: 10px;
}

.image-label {
  padding: 10px;
  background: #fff;
  border: 1px dashed #bdbdbd;
}

.image-label .span-icon {
  display: block;
}

.image-upload {
  cursor: pointer;
}

p.p-link {
  font-weight: 600;
}

span.link {
  color: #0bc196;
  text-decoration: none;
}

.swiper-button-next,
.swiper-button-prev {
  transform: scale(0.5);
  filter: hue-rotate(40deg);
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: -30px;
}
</style>
