var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main mb-45"},[_vm._m(0),_c('div',{staticClass:"full-page-bg-color w-100"},[_c('div',{staticClass:"vx-col mx-auto self-center"},[_c('div',{staticClass:"clearfix"}),_c('vs-alert',{attrs:{"active":_vm.active1,"closable":"","close-icon":"close"},on:{"update:active":function($event){_vm.active1=$event}}},[_vm._v(" Direct Debit arrangement has been set up successfully. "),_c('a',{attrs:{"href":_vm.mandateUrl,"target":"_blank"}},[_vm._v("Click here to view mandate")])]),_c('div',{staticClass:"sr-root"},[_c('div',{staticClass:"sr-main"},[_c('div',{staticClass:"sr-payment-form",attrs:{"id":"payment-form"}},[_c('div',{staticClass:"sr-combo-inputs-row"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Account Holder Name")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.bankDetails.accountHolderName),expression:"bankDetails.accountHolderName"}],staticClass:"nameInput",staticStyle:{"font-size":"16px !important"},attrs:{"type":"text","id":"name","name":"accountHolderName","placeholder":"John Smith","danger":_vm.errors.first('accountHolderName') ? true : false,"success":!_vm.errors.first('accountHolderName') &&
                      _vm.bankDetails.accountHolderName != '',"data-vv-validate-on":"blur","data-vv-as":"Account Holder Name"},domProps:{"value":(_vm.bankDetails.accountHolderName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bankDetails, "accountHolderName", $event.target.value)}}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("accountHolderName"))+" ")])])]),_c('div',{staticClass:"sr-combo-inputs-row",staticStyle:{"display":"none"}},[_c('div',{staticClass:"col",staticStyle:{"visibility":"hidden"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"},{name:"model",rawName:"v-model",value:(_vm.bankDetails.email),expression:"bankDetails.email"}],attrs:{"id":"email","name":"email","type":"email","placeholder":"john.smith@example.com","danger":_vm.errors.first('email') ? true : false,"success":!_vm.errors.first('email') && _vm.bankDetails.email != '',"data-vv-validate-on":"blur"},domProps:{"value":(_vm.bankDetails.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.bankDetails, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("email"))+" ")])])]),_vm._m(1),_c('div',{attrs:{"id":"bank-name"}}),_c('div',{attrs:{"id":"error-message","role":"alert"}}),_vm._m(2),_c('button',{attrs:{"disabled":_vm.isDisabled,"id":"confirm-mandate"},on:{"click":_vm.confirmBankDetails}},[_c('div',{staticClass:"spinner hidden",attrs:{"disabled":"","id":"spinner"}}),_vm._m(3)])]),_vm._m(4)])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-card__title mt-30"},[_c('h1',{staticClass:"text-center create-title"},[_vm._v(" Payment Details ")]),_c('div',{staticClass:"text-center mt-5 mb-5",staticStyle:{"width":"65%","margin-left":"auto","margin-right":"auto"}},[_c('span',{staticClass:"main-label"},[_vm._v(" It’s free to join the fresh clinics community! Only pay for your Doctor consultations. ")]),_c('span',{staticClass:"sub-label mt-5"},[_c('span',{staticStyle:{"display":"block"}},[_vm._v("Please note:")]),_vm._v("Fresh Clinics intends to create a subscription fee in the future. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sr-combo-inputs-row"},[_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":"au-bank-account-element"}},[_vm._v("Bank Account")]),_c('div',{attrs:{"id":"au-bank-account-element"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col",attrs:{"id":"mandate-acceptance"}},[_vm._v(" By providing your bank account details and confirming this payment, you agree to this Direct Debit Request and the "),_c('a',{attrs:{"href":"https://stripe.com/au-becs-dd-service-agreement/legal","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Direct Debit Request service agreement")]),_vm._v(", and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic Clearing System (BECS) on behalf of Stripe Press (the \"Merchant\") for any amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorised signatory on the account listed above. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"payment",attrs:{"id":"button-text"}},[_c('span',{staticClass:"payment"},[_vm._v("Confirm")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sr-result hidden"},[_c('p',[_vm._v(" Response "),_c('br')]),_c('pre',[_vm._v("          "),_c('code'),_vm._v("\n        ")])])
}]

export { render, staticRenderFns }