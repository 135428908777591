<template>
  <section>
    <header>
      <div class="logo">
        <img
          class="w-10 mr-4 fill-current text-primary"
          src="../../../../../public/Group-51.png"
        />
        <img src="../../../../../public/Group-23.png" width="125px" />
      </div>
    </header>

    <div class="account-detail" v-if="user">
      <div
        style="text-align: right; text-align: right; padding-right: 30px"
        v-if="$store.state.AppActiveUser._id"
        class="account-detail-name"
      >
        <span>
          {{ $store.state.AppActiveUser.firstName }}
          {{ $store.state.AppActiveUser.lastName }}
        </span>
      </div>

      <div
        style="text-align: right; text-align: right; padding-right: 30px"
        v-if="$store.state.AppActiveUser._id"
        class="account-detail-logout"
      >
        <span
          style="text-decoration: underline; cursor: pointer"
          @click="logout"
          >Logout</span
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      user: undefined,
    };
  },
  methods: {
    logout() {
      // If JWT login
      if (
        localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        )
      ) {
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
        );
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );

        this.$cookies.remove(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
        );
        localStorage.removeItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);
        localStorage.removeItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );
        this.$router.push("/login").catch(() => {});
      }

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change("admin");
      localStorage.removeItem("userInfo");

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/login").catch(() => {});
    },
  },
  created() {
    this.user = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
  },
};
</script>

<style scoped>
section {
  position: relative;
  width: 100%;
  height: 72px;
  background: #ffffff;
}

header {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
}

div.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-detail {
  text-align: right;
  padding-right: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}

.account-detail-name {
  top: 20px;
}

.account-detail-logout {
  bottom: 20px;
}
</style>
