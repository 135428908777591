<template>
  <div class="main mb-45">
    <div class="vx-card__title mt-30">
      <h1 class="text-center create-title">
        Payment Details
      </h1>

      <div
        style="width: 65%; margin-left: auto; margin-right: auto;"
        class="text-center mt-5 mb-5"
      >
        <span class="main-label">
          It’s free to join the fresh clinics community! Only pay for your
          Doctor consultations.
        </span>

        <span class="sub-label mt-5">
          <span style="display: block;">Please note:</span>Fresh Clinics intends
          to create a subscription fee in the future.
        </span>
      </div>
    </div>

    <div class="full-page-bg-color w-100" style="">
      <div class="vx-col mx-auto self-center">
        <div class="clearfix"></div>
        <vs-alert :active.sync="active1" closable close-icon="close">
          Direct Debit arrangement has been set up successfully.
          <a :href="mandateUrl" target="_blank">Click here to view mandate</a>
        </vs-alert>
        <div class="sr-root">
          <div class="sr-main">
            <div id="payment-form" class="sr-payment-form">
              <div class="sr-combo-inputs-row">
                <div class="col">
                  <label for="name">Account Holder Name</label>
                  <input
                    class="nameInput"
                    style="font-size: 16px !important;"
                    v-validate="'required'"
                    type="text"
                    id="name"
                    name="accountHolderName"
                    placeholder="John Smith"
                    :danger="errors.first('accountHolderName') ? true : false"
                    :success="
                      !errors.first('accountHolderName') &&
                        bankDetails.accountHolderName != ''
                    "
                    data-vv-validate-on="blur"
                    data-vv-as="Account Holder Name"
                    v-model="bankDetails.accountHolderName"
                  />
                  <span class="text-danger text-sm">
                    {{ errors.first("accountHolderName") }}
                  </span>
                </div>
              </div>

              <div class="sr-combo-inputs-row" style="display: none;">
                <div class="col" style="visibility: hidden;">
                  <label for="email">Email Address</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="john.smith@example.com"
                    v-validate="'required|email'"
                    :danger="errors.first('email') ? true : false"
                    :success="!errors.first('email') && bankDetails.email != ''"
                    data-vv-validate-on="blur"
                    v-model="bankDetails.email"
                  />

                  <span class="text-danger text-sm">
                    {{ errors.first("email") }}
                  </span>
                </div>
              </div>

              <div class="sr-combo-inputs-row">
                <div class="col">
                  <label for="au-bank-account-element">Bank Account</label>
                  <div id="au-bank-account-element">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                </div>
              </div>
              <div id="bank-name"></div>

              <!-- Used to display form errors. -->
              <div id="error-message" role="alert"></div>

              <!-- Display mandate acceptance text. -->
              <div class="col" id="mandate-acceptance">
                By providing your bank account details and confirming this
                payment, you agree to this Direct Debit Request and the
                <a
                  href="https://stripe.com/au-becs-dd-service-agreement/legal"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Direct Debit Request service agreement</a
                >, and authorise Stripe Payments Australia Pty Ltd ACN 160 180
                343 Direct Debit User ID number 507156 (“Stripe”) to debit your
                account through the Bulk Electronic Clearing System (BECS) on
                behalf of Stripe Press (the "Merchant") for any amounts
                separately communicated to you by the Merchant. You certify that
                you are either an account holder or an authorised signatory on
                the account listed above.
              </div>

              <button
                :disabled="isDisabled"
                id="confirm-mandate"
                @click="confirmBankDetails"
              >
                <div disabled class="spinner hidden" id="spinner"></div>
                <span id="button-text" class="payment">
                  <span class="payment">Confirm</span>
                </span>
              </button>
            </div>
            <div class="sr-result hidden">
              <p>
                Response
                <br />
              </p>
              <pre>
            <code></code>
          </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";

export default {
  name: "ClinicBankDetailSetup",
  data() {
    return {
      stripe: null,
      isDisabled: false,
      active1: false,
      mandateUrl: "",
      bankDetails: {
        accountHolderName:
          this.$store.state.AppActiveUser.financialDetails &&
          this.$store.state.AppActiveUser.financialDetails.accountHolderName
            ? this.$store.state.AppActiveUser.financialDetails.accountHolderName
            : this.$store.state.AppActiveUser.companyName,
        email:
          this.$store.state.AppActiveUser.financialDetails &&
          this.$store.state.AppActiveUser.financialDetails.accountHolderEmail
            ? this.$store.state.AppActiveUser.financialDetails
                .accountHolderEmail
            : this.$store.state.AppActiveUser.email,
        bsb: "",
        accountNumber: "",
        auBankAccount: ""
      },
      formSubmit: false
    };
  },
  methods: {
    ...mapActions("onboard", [
      "getStripePublishableKey",
      "createSetupIntent",
      "getMandateObject"
    ]),
    getPublisableKey() {
      this.$vs.loading();
      this.getStripePublishableKey()
        .then(res => {
          this.stripe = Stripe(res.data.publishableKey, {
            betas: ["au_bank_account_beta_2"]
          });

          const { auBankAccount } = this.setupElements();

          this.bankDetails.auBankAccount = auBankAccount;
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.notify({
            title: "Error",
            text: "Oops ! something went wrong. Please try later.",
            color: "danger"
          });
          this.$vs.loading.close();
        });
    },
    setupElements() {
      const self = this;
      const elements = self.stripe.elements();
      // Custom styling can be passed to options when creating an Element
      const style = {
        base: {
          color: "#32325d",
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          },
          ":-webkit-autofill": {
            color: "#32325d"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
          ":-webkit-autofill": {
            color: "#fa755a"
          }
        }
      };

      const options = {
        style: style,
        disabled: false,
        hideIcon: false,
        iconStyle: "default" // or "solid"
      };

      // Create an instance of the auBankAccount Element.
      const auBankAccount = elements.create("auBankAccount", options);

      // Add an instance of the auBankAccount Element into
      // the `au-bank-account-element` <div>.
      auBankAccount.mount("#au-bank-account-element");

      auBankAccount.on("change", function(event) {
        // Reset error state
        document.getElementById("error-message").classList.remove("visible");
        // Display bank name corresponding to auBankAccount, if available.
        const bankName = document.getElementById("bank-name");
        if (event.bankName && event.branchName) {
          bankName.textContent = `${event.bankName} (${event.branchName})`;
          bankName.classList.add("visible");
        } else if (event.bankName) {
          bankName.textContent = `${event.bankName}`;
          bankName.classList.add("visible");
        } else {
          bankName.classList.remove("visible");
        }
        // Handle real-time validation errors from the Element.
        if (event.error) {
          self.formSubmit = false;
          self.showError(event.error.message);
        } else {
          self.formSubmit = true;
        }
      });

      return {
        auBankAccount: auBankAccount
      };
    },
    showError(errorMsgText) {
      this.changeLoadingState(false);
      const errorMsg = document.querySelector("#error-message");
      errorMsg.textContent = errorMsgText;
      errorMsg.classList.add("visible");
      this.$vs.notify({
        title: "Error",
        text: errorMsgText,
        color: "danger"
      });
    },
    changeLoadingState(isLoading) {
      if (isLoading) {
        this.isDisabled = true;
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        this.isDisabled = false;
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    },
    async confirmBankDetails() {
      let isValid = await this.$validator.validateAll();

      if (isValid && !this.formSubmit) {
        this.$vs.notify({
          title: "Warning",
          text:
            "Please complete the form completely before submitting the form.",
          color: "danger"
        });
      }

      if (isValid && this.formSubmit) {
        this.changeLoadingState(true);
        this.createSetupIntent({ userId: this.$store.state.AppActiveUser._id })
          .then(created => {
            return this.stripe.confirmAuBecsDebitSetup(created.data.data, {
              payment_method: {
                au_becs_debit: this.bankDetails.auBankAccount,
                billing_details: {
                  name: this.bankDetails.accountHolderName,
                  email: this.bankDetails.email
                }
              }
            });
          })
          .then(res => {
            return this.getMandateObject({
              userId: this.$store.state.AppActiveUser._id,
              intentId: res.setupIntent.id,
              accountHolderName: this.bankDetails.accountHolderName,
              accountHolderEmail: this.bankDetails.email
            });
          })
          .then(res => {
            this.$emit("changeCurrentStep", "Completed");
          })
          .catch(err => {
            const errMessage =
              err.data && err.data.message
                ? err.data.message
                : err.message
                ? err.message
                : "Oops ! something went wrong. Please try later.";

            this.changeLoadingState(false);
          });
      }
    }
  },
  created() {
    this.getPublisableKey();
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400&display=swap");

.main {
  font-family: "Inter", sans-serif;
  line-height: 120%;
  letter-spacing: -0.01em;
}

.create-title {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  letter-spacing: -0.01em;
  text-align: center;
  color: #074230;
}
/**
* The CSS shown here will not be introduced in the Quickstart guide, but
* shows how you can use CSS to style your Element's container.
*/

/* Base */
* {
  box-sizing: border-box;
}

/* Layout */
.sr-root {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  justify-content: center;
  height: auto;
  margin: 0 auto;
}
.sr-main {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-self: center;
  padding: 75px 50px;
  background: rgb(247, 250, 252);
  border-radius: 6px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}

/* Inputs */
input,
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  width: 100%;
  outline: none;
  font-size: 0.875rem !important;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: rgba(var(--vs-danger), 1) !important;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

input::placeholder {
  color: #aab7c4;
  font-size: 0.875rem !important;
}

.sr-result {
  height: 44px;
  -webkit-transition: height 1s ease;
  -moz-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
  color: rgb(50, 50, 93);
  overflow: auto;
}
.sr-result code {
  overflow: scroll;
}
.sr-result.expand {
  height: 350px;
}

.sr-combo-inputs-row {
  display: -ms-flexbox;
  display: flex;
}
.sr-combo-inputs-row {
  width: 100%;
  margin-top: 20px;
}
.sr-combo-inputs-row:first-child {
  margin-top: 0;
}
.sr-combo-inputs-row {
  margin-top: 20px;
}
.sr-combo-inputs-row .col:not(:last-child) {
  margin-right: 20px;
}
.sr-combo-inputs-row .col {
  width: 100%;
}

.InputElement {
  font-size: 0.875rem !important;
}

.ElementsApp,
.ElementsApp .InputElement {
  font-size: 13px !important;
}

/* Input labels */
label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  max-width: 100%;
  color: #6b7c93;
}

/* Buttons and links */
button {
  background: rgb(50, 50, 93);
  border-radius: 6px;
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

#button-text:not(.payment) span.payment {
  display: none;
}
#button-text:not(.setup) span.setup {
  display: none;
}

a {
  color: rgb(107, 124, 147);
  text-decoration: underline;
  transition: all 0.2s ease;
}

a:hover {
  filter: brightness(0.8);
}

a:active {
  filter: brightness(0.5);
}

/* Code block */
code,
pre {
  font-family: "SF Mono", "IBM Plex Mono", "Menlo", monospace;
  font-size: 12px;
}

/* Stripe Element placeholder */
.sr-element {
  padding-top: 12px;
}

/* Responsiveness */
@media (max-width: 720px) {
  .sr-root {
    flex-direction: column;
    justify-content: flex-start;
    padding: 48px 20px;
    min-width: 320px;
  }

  .sr-header__logo {
    background-position: center;
  }

  .sr-payment-summary {
    text-align: center;
  }

  .sr-content {
    display: none;
  }

  .sr-main {
    width: 100%;
    height: 450px;
    background: rgb(247, 250, 252);
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
  }
}

/* spinner/processing state, errors, animations */

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: rgb(50, 50, 93);
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: rgb(50, 50, 93);
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Animated form */

.sr-root {
  animation: 0.4s form-in;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.hidden {
  display: none;
}

@keyframes field-in {
  0% {
    opacity: 0;
    transform: translateY(8px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

@keyframes form-in {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Form element styling */

#bank-name + button {
  margin-top: 12px;
}

#bank-name {
  margin-top: 8px;
}

#error-message {
  margin-top: 12px;
  color: rgba(var(--vs-danger), 1) !important;
  font-size: 0.875rem !important;
}

#error-message,
#bank-name {
  min-height: 20px;
  font-size: 14px;
  opacity: 0;
  transform: translateY(4px);
  transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

#error-message.visible,
#bank-name.visible {
  opacity: 1;
  transform: translateY(0);
}

#mandate-acceptance {
  margin-top: 8px;
  font-size: 14px;
  text-align: justify;
}

::-webkit-input-placeholder {
  color: #aab7c4;
  font-size: 0.875rem !important;
}
::-moz-placeholder {
  color: #aab7c4;
  font-size: 0.875rem !important;
}
:-ms-input-placeholder {
  color: #aab7c4;
  font-size: 0.875rem !important;
}
:-moz-placeholder {
  color: #aab7c4;
  font-size: 0.875rem !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-45 {
  margin-bottom: 45px;
}

.main-label {
  font-weight: 600;
  font-size: 14px;
  color: #074230;
}

.sub-label {
  display: block;
  font-size: 0.95rem;
  color: #074230;
  margin-top: 3px;
}
</style>
